<template>
  <div
    class="content row"
    style="margin-top: 10px; background: linear-gradient(#234039, black)"
  >
    <div class="col-sm-12 col-md-12 col-xl-12">
      <div class="header text-center" style="margin-bottom: 5px">
        <img
          src="https://imgix.cosmicjs.com/14322b50-1301-11ec-b1d5-4d3fd4454d55-image.png?w=380px"
          height="380px"
        />

        <h1 class="tulum-party-title text-center" style="margin-top: -320px">
          Event Production Brands
        </h1>
        <h3 class="section-header">#TULUM</h3>

        <!--        <div class="centered">-->
        <!--          <img-->
        <!--            src="https://imgix.cosmicjs.com/be0bf050-e6fa-11ee-b074-b5c8fe3ef189-transparent-palm.png?w=240&h=260&fit=clamp&duotone=black,silver&duotone-alpha=100&px=10`"-->
        <!--            height="260px"-->
        <!--            width="240px"-->
        <!--          />-->
        <!--          <img-->
        <!--            src="https://imgix.cosmicjs.com/be0bf050-e6fa-11ee-b074-b5c8fe3ef189-transparent-palm.png?w=240&h=260&fit=clamp&duotone=black,silver&duotone-alpha=100&px=10`"-->
        <!--            height="260px"-->
        <!--            width="240px"-->
        <!--            style="transform: rotateY(180deg)"-->

        <!--          />-->
        <!--          <h3>TULUM MEXICO</h3>-->

        <!--        </div>-->
        <div
          class="card centered text-center"
          style="background-color: #1f1f1f; padding: 10px"
        >
          <p style="max-width: 700px" class="centered text-center">
            Tulum is one of the Hottest places for
            <b>Techno and House Music in the world</b>, some of the most
            important, popular and exclusive event production brands from all
            around the world also have their solid stance in the coasts of the
            Riviera Maya.
          </p>
          <br />

          <p class="centered text-center">
            <b>High Quality Production brands</b> are an<b> essential </b> piece
            of the party landscape at Tulum.
            <br />
            They are in charge to keep the party going in and around Tulum.
          </p>
        </div>

        <div class="lds-dual-ring" v-if="this.$store.state.status.loading">
          <h4>Spinning the Hamster.</h4>
        </div>
        <div id="brands" class="row">
          <div
            v-for="(brand, idx) in brands"
            class="col-sm-6 col-md-6"
            :key="idx"
          >
            <div
              class="card"
              v-if="brandConfig[brand.slug]"
              style="
                width: 100%;
                padding: 15px;
                margin-top: 10px;
                background: linear-gradient(#358f76, black);
              "
            >
              <div
                id="image"
                class="text-center"
                :style="{
                  background: `linear-gradient(${
                    brandConfig[brand.slug].color
                  }, black)`,
                }"
              >
                <router-link :to="{ path: '/brands/' + brand.slug }">
                  <img
                    v-lazy="
                      brand.metadata.main_image.imgix_url +
                      '?w=315&h=200&fit=clamp&auto=format'
                    "
                    style="
                      margin-top: -10px;
                      z-index: 12;
                      box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);
                      border-radius: 0;
                    "
                    v-bind:alt="brand.title"
                    width="315px"
                    height="200px"
                  />
                </router-link>
              </div>

              <h2
                class="tulum-party-title text-center"
                style="
                  font-size: 1.2rem;
                  color: #ffffff !important;
                  margin-top: 20px;
                  text-transform: uppercase;
                "
              >
                {{ brand.title }}
              </h2>

              <small class="text-center" style="margin-top: -20px">
                {{
                  brandConfig[brand.slug] && brandConfig[brand.slug].legend
                }}</small
              >

              <br />

              <!--              <div v-html="brand.metadata.description" class="text-center"></div>-->
              <div
                :key="idx"
                v-for="(event, idx) in incomingParties.filter((party) =>
                  partyBrand(brand, party)
                )"
              >
                <h4 class="section-header" v-if="idx === 0">Upcoming Events</h4>

                <MiniEventCard
                  :withLineup="false"
                  :withVenue="true"
                  :with-title="false"
                  v-bind:event="event"
                >
                </MiniEventCard>
              </div>
            </div>
            <br />
          </div>
        </div>
        <br />
        <hr />
      </div>
      <div class="centered card">
        <h4 class="section-header">Upcoming Events</h4>
        <div v-for="event in incomingParties" :key="event.slug">
          <EventBrand :title="event.title"></EventBrand>
          <MiniEventCard
            :withLineup="false"
            :with-title="false"
            v-bind:event="event"
          >
          </MiniEventCard>
        </div>
      </div>
      <div>
        <h4 class="section-header text-left">
          <img
            v-lazy="tulumPartyGreen + '?h=40&w=40'"
            height="40px"
            width="40px"
            style="border-radius: 300px"
          />
          RECENT
        </h4>
        <div v-if="links.length">
          <DynamicLinks
            :links="links.slice(10, 20)"
            size="sm"
            style="background: linear-gradient(mediumaquamarine, black)"
          >
            >
          </DynamicLinks>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import brandsConfig from '../../common/brandsConfig';
import MiniEventCard from '@/components/EventCard/MiniEventCard';
import EventBrand from '@/components/EventCard/EventBrand';
import DynamicLinks from '@/components/DynamicLinks';

export default {
  name: 'Brands',
  metaInfo() {
    return {
      title: `Tulum ✦ Event Production Brands`,
      meta: [
        {
          name: 'description',
          content: `Tulum Event Production Brands`,
        },
        {
          name: 'keywords',
          content: `Tulum Event Production Brands Organizer`,
        },
      ],
    };
  },
  props: ['slug'],
  methods: {
    partyBrand(brand, party) {
      return (
        party.slug.indexOf(
          brand.slug
            .toLowerCase()
            .replaceAll('tulum', '')
            .replaceAll(/global/g, '')
        ) > -1
      );
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    },
  },
  data: () => {
    return {
      showPalms: false,
      tulumPartyGreen:
        'https://imgix.cosmicjs.com/717f22a0-d081-11ee-9ce5-59949019255e-tulum-party-green.jpg',
      brandsRegexp: /ancestral|mystical|set-underground|piknik|sinner|afterlife|day-zero|ibiza|keinemusik|zamna/,
      brandConfig: {},
      val: undefined,
      showModal: false,
    };
  },
  components: {
    EventBrand,
    MiniEventCard,
    DynamicLinks,
  },
  created: function () {
    this.$store.dispatch('getAllParties').then((_x) => {
      this.brandConfig = brandsConfig;
      this.$store.dispatch('getBrands');
    });
    this.$store.dispatch('getLinks', { slug: 'tulum' });
  },
  computed: {
    brands: {
      get() {
        return this.$store.state.brands;
      },
    },
    links: {
      get() {
        return this.shuffleArray(this.$store.state.links || []);
      },
    },
    brandNames: {
      get() {
        return (
          this.$store.state.brands &&
          this.$store.state.brands.map((brand) => brand.title.toLowerCase())
        );
      },
    },
    incomingParties: {
      get() {
        return this.$store.state.calendar.filter((party) => {
          console.log(party.slug);
          return party.slug.match(this.brandsRegexp);
        });
      },
    },
  },
};
</script>
